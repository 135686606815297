import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Servis from "../../../services/servis";

const VeriDuzenleModal = ({
  show,
  handleClose,
  veri,
  yil,
  baslikId,
  handleUpdate,
}) => {
  const [formData, setFormData] = useState({
    ocak: "",
    subat: "",
    mart: "",
    nisan: "",
    mayis: "",
    haziran: "",
    temmuz: "",
    agustos: "",
    eylul: "",
    ekim: "",
    kasim: "",
    aralik: "",
  });

  // veri değiştiğinde formData'yı güncelle
  useEffect(() => {
    if (veri) {
      setFormData({
        ocak: veri?.aylar?.find((a) => a.ay === "Ocak")?.veri || "",
        subat: veri?.aylar?.find((a) => a.ay === "Şubat")?.veri || "",
        mart: veri?.aylar?.find((a) => a.ay === "Mart")?.veri || "",
        nisan: veri?.aylar?.find((a) => a.ay === "Nisan")?.veri || "",
        mayis: veri?.aylar?.find((a) => a.ay === "Mayıs")?.veri || "",
        haziran: veri?.aylar?.find((a) => a.ay === "Haziran")?.veri || "",
        temmuz: veri?.aylar?.find((a) => a.ay === "Temmuz")?.veri || "",
        agustos: veri?.aylar?.find((a) => a.ay === "Ağustos")?.veri || "",
        eylul: veri?.aylar?.find((a) => a.ay === "Eylül")?.veri || "",
        ekim: veri?.aylar?.find((a) => a.ay === "Ekim")?.veri || "",
        kasim: veri?.aylar?.find((a) => a.ay === "Kasım")?.veri || "",
        aralik: veri?.aylar?.find((a) => a.ay === "Aralık")?.veri || "",
      });
    }
  }, [veri]);

  if (!veri || !show) {
    return null;
  }

  const handleAylarChange = (e, ay) => {
    setFormData({ ...formData, [ay]: e.target.value });
  };

  // Veri güncelleme işlevi
  const handleUpdateClick = async () => {
    const aylar = [
      { ay: "Ocak", veri: formData.ocak },
      { ay: "Şubat", veri: formData.subat },
      { ay: "Mart", veri: formData.mart },
      { ay: "Nisan", veri: formData.nisan },
      { ay: "Mayıs", veri: formData.mayis },
      { ay: "Haziran", veri: formData.haziran },
      { ay: "Temmuz", veri: formData.temmuz },
      { ay: "Ağustos", veri: formData.agustos },
      { ay: "Eylül", veri: formData.eylul },
      { ay: "Ekim", veri: formData.ekim },
      { ay: "Kasım", veri: formData.kasim },
      { ay: "Aralık", veri: formData.aralik },
    ];
    const sonuc = await Servis.postYap("veri/veriguncelle", {
      yil,
      baslikId,
      aylar,
    });
    if (sonuc.status) {
      // Sonucun başarılı olup olmadığını kontrol edin
      handleUpdate();
      handleClose();
    } else {
      // Hata durumunda kullanıcıya bilgi verilebilir
      console.error("Veri güncelleme hatası:", sonuc.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {yil} Yılı {veri.veriadi} Verileri
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Ocak</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.ocak}
                  onChange={(e) => handleAylarChange(e, "ocak")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Şubat</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.subat}
                  onChange={(e) => handleAylarChange(e, "subat")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mart</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.mart}
                  onChange={(e) => handleAylarChange(e, "mart")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Nisan</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.nisan}
                  onChange={(e) => handleAylarChange(e, "nisan")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mayıs</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.mayis}
                  onChange={(e) => handleAylarChange(e, "mayis")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Haziran</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.haziran}
                  onChange={(e) => handleAylarChange(e, "haziran")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Temmuz</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.temmuz}
                  onChange={(e) => handleAylarChange(e, "temmuz")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Ağustos</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.agustos}
                  onChange={(e) => handleAylarChange(e, "agustos")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Eylül</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.eylul}
                  onChange={(e) => handleAylarChange(e, "eylul")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Ekim</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.ekim}
                  onChange={(e) => handleAylarChange(e, "ekim")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Kasım</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.kasim}
                  onChange={(e) => handleAylarChange(e, "kasim")}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Aralık</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.aralik}
                  onChange={(e) => handleAylarChange(e, "aralik")}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Kapat
        </Button>
        <Button variant="outline-primary" onClick={handleUpdateClick}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VeriDuzenleModal;
