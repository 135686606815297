import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet"; // Import Leaflet
import "leaflet/dist/leaflet.css";
import "./YatirimEkle.css"; // Import the CSS file
import {  Button } from "react-bootstrap";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const PolygonDrawer = ({ positions, setPositions }) => {
  useMapEvents({
    click(e) {
      setPositions([...positions, e.latlng]);
    },
  });

  return (
    <Polygon positions={positions}>
      <Popup>
        Polygonun köşeleri: <br />
        {positions.map((pos, index) => (
          <div key={index}>
            {pos.lat.toFixed(5)}, {pos.lng.toFixed(5)}
          </div>
        ))}
      </Popup>
    </Polygon>
  );
};

const YatirimEkleYeni = () => {
  const [polygonPositions, setPolygonPositions] = useState([]);
  const [tileLayerUrl, setTileLayerUrl] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const [is3D, setIs3D] = useState(false);

  const handleTileLayerChange = (e) => {
    setTileLayerUrl(e.target.value);
  };

  const toggle3D = () => {
    setIs3D(!is3D);
  };

  return (
    <div>
      <select onChange={handleTileLayerChange}>
        <option value="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png">
          OpenStreetMap
        </option>
        <option value="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png">
          OpenTopoMap
        </option>
        <option value="https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png">
          Thunderforest Cycle
        </option>
      </select>
      <button onClick={toggle3D}>{is3D ? "2D Görünüm" : "3D Görünüm"}</button>
      <Button variant="primary" onClick={() => setPolygonPositions([])}>
        Sıfırla
      </Button>
      <MapContainer
        center={[38.36869, 34.03698]} // Change initial center to Aksaray
        zoom={13}
        className="map-container" // Add a CSS class
      >
        <TileLayer
          url={tileLayerUrl}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. <a href="https://www.openstreetmap.org/copyright">Lisans</a>'
        />
        {is3D && (
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.opentopomap.org/copyright">OpenTopoMap</a> contributors'
          />
        )}
        <PolygonDrawer
          positions={polygonPositions}
          setPositions={setPolygonPositions}
        />
      </MapContainer>
    </div>
  );
};

export default YatirimEkleYeni;
