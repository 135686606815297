import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Alert,
} from "react-bootstrap";

import HtmlHead from "components/html-head/HtmlHead";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Select from "react-select";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import Servis from "../../services/servis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ilceler } from "../../config";

const AsayisVeriGiris = () => {
  const [seciliilce, setSeciliilce] = useState(null);
  const [ilceler, setIlceler] = useState([]);
  const [koymahalleler, setKoymahalleler] = useState([]);
  const [basliklar, setBasliklar] = useState([]);
  const { currentUser } = useSelector((state) => state.auth);
  const [btnLoading, setBtnLoading] = useState(false);
  const [grupluBasliklar, setGrupluBasliklar] = useState({});

  useEffect(() => {
    Servis.getYap("asayis/basliklistele")
      .then((res) => {
        // Başlıkları üst başlıklarına göre grupla
        const gruplar = res.data.reduce((acc, baslik) => {
          if (!acc[baslik.ustbaslik]) {
            acc[baslik.ustbaslik] = [];
          }
          acc[baslik.ustbaslik].push(baslik);
          return acc;
        }, {});
        
        setGrupluBasliklar(gruplar);
        setBasliklar(res.data);
      })
      .catch((err) => {
        console.error("Başlık listesi çekme hatası:", err);
        toast("Başlık listesi alınamadı", {
          className: "error",
        });
      });
  }, []);

  useEffect(() => {
    Servis.getYap("asayis/mahallelistesipostgre")
      .then((res) => {
        // Kuruma göre filtrele
        const kurumVerileri = res.data.filter(item => 
          item.asayiskurum === currentUser.kurumadi
        );

        // İlçeleri unique olarak al
        const uniqueIlceler = [...new Set(kurumVerileri.map(item => item.ilce_adi))]
          .sort((a, b) => a.localeCompare(b, 'tr'))
          .map(ilce => ({
            value: ilce,
            label: ilce
          }));
        setIlceler(uniqueIlceler);
      })
      .catch(err => {
        console.error("İlçe listesi çekme hatası:", err);
        toast("İlçe listesi alınamadı", {
          className: "error",
        });
      });
  }, [currentUser.kurumadi]);

  // Form doğrulama şeması
  const validationSchema = Yup.object().shape({
    tarih: Yup.date()
      .required("Tarih alanı zorunludur")
      .nullable()
      .max(new Date(), "Gelecek bir tarih seçemezsiniz"),
    seciliilce: Yup.object() // string yerine object olarak değiştirildi
      .required("İlçe seçilmedi")
      .nullable(),
    secilikoymahalle: Yup.object() // string yerine object olarak değiştirildi
      .required("Köy/Mahalle seçilmedi")
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      tarih: null,
      seciliilce: null,
      secilikoymahalle: null,
      veriler: {},
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);

        // Validasyonlar
        if (!values.tarih) {
          toast("Tarih seçilmedi", {
            className: "error",
          });
          setBtnLoading(false);
          return;
        }

        if (!values.seciliilce) {
          toast("İlçe seçilmedi", {
            className: "error",
          });
          setBtnLoading(false);
          return;
        }

        if (!values.secilikoymahalle) {
          toast("Köy/Mahalle seçilmedi", {
            className: "error",
          });
          setBtnLoading(false);
          return;
        }

        const formattedData = Object.entries(values.veriler).map(
          ([id, deger]) => {
            const baslik = basliklar.find((b) => b._id === id);
            const secilenTarih = new Date(values.tarih);
            secilenTarih.setDate(secilenTarih.getDate() + 1);
            secilenTarih.setUTCHours(0, 0, 0, 0);

            return {
              tarih: secilenTarih,
              kurum: currentUser.kurumadi,
              ilce: values.seciliilce?.value,
              koymahalle: values.secilikoymahalle?.label,
              veriadi: baslik.adi,
              ustbaslik: baslik.ustbaslik,
              veri: deger === "" ? 0 : Number(deger),
              objectid: values.secilikoymahalle?.value,
            };
          }
        );

        // Önce verileri sil
        await Servis.postYap("asayis/verisil", {
          tarih: formattedData[0].tarih,
          kurum: currentUser.kurumadi,
          ilce: formattedData[0].ilce,
          objectid: formattedData[0].objectid,
        });

        // Sonra yeni verileri kaydet
        const res = await Servis.postYap(
          "asayis/verikaydetyeni",
          formattedData
        );

        setBtnLoading(false);

        if (res.status) {
          toast("Veriler başarıyla kaydedildi", {
            className: "success",
          });
          formik.resetForm();
          setSeciliilce(null);
          setKoymahalleler([]);
        } else {
          toast("Veri kaydedilirken bir hata oluştu", {
            className: "error",
          });
        }
      } catch (err) {
        console.error("Veri kaydetme hatası:", err);
        setBtnLoading(false);
        toast("Veri kaydedilirken bir hata oluştu", {
          className: "error",
        });
      }
    },
  });

  // Köy/mahalle seçildiğinde veri kontrolü yapacak useEffect
  useEffect(() => {
    if (
      formik.values.tarih &&
      formik.values.seciliilce &&
      formik.values.secilikoymahalle
    ) {
      const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split("T")[0];
      };

      const params = {
        tarih: formatDate(formik.values.tarih),
        kurum: currentUser.kurumadi,
        ilce: formik.values.seciliilce.value,
        koymahalle: formik.values.secilikoymahalle.label,
        objectid: formik.values.secilikoymahalle.value,
      };

      Servis.postYap("asayis/verivarsagetir", params)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            const yeniVeriler = {};
            res.data.forEach((item) => {
              
              const baslik = basliklar.find(
                (b) =>
                  b.adi === item.veriadi && b.ustbaslik === item.ustbaslik
              );
              if (baslik) {
                yeniVeriler[baslik._id] = item.veri.toString();
              }
            });
            formik.setFieldValue("veriler", yeniVeriler);
          } else {
            formik.setFieldValue("veriler", {});
          }
        })
        .catch((err) => {
          console.error("Veri getirme hatası:", err);
        });
    }
  }, [formik.values.secilikoymahalle, formik.values.tarih]);

  const ilceDegistir = (selectedOption) => {
    formik.setFieldValue("seciliilce", selectedOption);
    formik.setFieldValue("secilikoymahalle", null);
    formik.setFieldValue("veriler", {});

    if (selectedOption) {
      Servis.getYap("asayis/mahallelistesipostgre")
        .then((res) => {
          // Kuruma göre filtrele
          const kurumVerileri = res.data.filter(item => 
            item.asayiskurum === currentUser.kurumadi
          );

          // Seçili ilçeye göre filtrele
          const filteredMahalleler = kurumVerileri
            .filter(item => item.ilce_adi === selectedOption.value)
            .sort((a, b) => a.yeradi.localeCompare(b.yeradi, 'tr'))
            .map(item => ({
              value: item.new_id,
              label: item.yeradi
            }));
          setSeciliilce(selectedOption);
          setKoymahalleler(filteredMahalleler);
        })
        .catch(err => {
          console.error("Mahalle listesi çekme hatası:", err);
          toast("Mahalle listesi alınamadı", {
            className: "error",
          });
        });
    } else {
      setSeciliilce(null);
      setKoymahalleler([]);
    }
  };

  return (
    <>
      <HtmlHead title="Veri Ekle" description="" />
      <Row className="g-2 mb-5">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <h2>Asayiş Veri Girişi</h2>

              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md="3" className="mb-3">
                    <Form.Group>
                      <Form.Label>Tarih</Form.Label>
                      <DatePicker
                        selected={formik.values.tarih}
                        onChange={(date) => {
                          formik.setFieldValue("tarih", date);
                          formik.setFieldValue("veriler", {}); // tarihi değiştiğinde verileri sıfırla
                        }}
                        locale={tr}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        className={`form-control ${
                          formik.touched.tarih && formik.errors.tarih
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholderText="Tarih seçiniz"
                        isClearable
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {formik.touched.tarih && formik.errors.tarih && (
                        <div className="invalid-feedback">
                          {formik.errors.tarih}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Form.Group>
                      <Form.Label>İlçe</Form.Label>
                      <Select
                        options={ilceler}
                        onChange={ilceDegistir}
                        value={formik.values.seciliilce}
                        classNamePrefix="react-select"
                        placeholder="İlçe Seçiniz"
                        isClearable
                        isInvalid={formik.touched.seciliilce && formik.errors.seciliilce}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <Form.Label>Köy Mahalle</Form.Label>
                      <Select
                        options={koymahalleler}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("secilikoymahalle", selectedOption);
                          formik.setFieldValue("veriler", {});
                        }}
                        value={formik.values.secilikoymahalle}
                        classNamePrefix="react-select"
                        placeholder="Köy/Mahalle Seçiniz"
                        isClearable
                        isInvalid={formik.touched.secilikoymahalle && formik.errors.secilikoymahalle}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3 mt-3">
                  <Table responsive className="mb-3 mt-3">
                    
                    <tbody>
                      {Object.entries(grupluBasliklar).map(([ustBaslik, basliklar]) => (
                        <React.Fragment key={ustBaslik}>
                          {/* Üst başlık satırı */}
                          <tr className="table-secondary">
                            <td colSpan="2" className="fw-bold text-danger fs-5">
                              {ustBaslik}
                            </td>
                          </tr>
                          {/* Alt başlıklar */}
                          {basliklar.map((baslik) => (
                            <tr key={baslik._id}>
                              <td className="align-middle">
                                <span className="fw-bold ps-3">
                                  {baslik.adi}
                                </span>
                              </td>
                              <td>
                                <NumberFormat
                                  value={formik.values.veriler[baslik._id] || ""}
                                  onValueChange={(values) => {
                                    formik.setFieldValue(
                                      `veriler.${baslik._id}`,
                                      values.value
                                    );
                                  }}
                                  className="form-control"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={2}
                                  allowNegative={false}
                                />
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={btnLoading}
                      className="mb-1"
                      size="xl"
                    >
                      {btnLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      ) : null}
                      {btnLoading ? "Kaydediliyor..." : "Kaydet"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AsayisVeriGiris;
