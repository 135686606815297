import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import HtmlHead from "components/html-head/HtmlHead";
import Select from "react-select";
import { DownloadTableExcel } from "react-export-table-to-excel";
import VeriDuzenleModal from "./Container/VeriDuzenleModal";
import Servis from "../../services/servis";

const KurumVerileri = () => {
  const [veriler, setVeriler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yillar, setYillar] = useState([]);
  const [selectedYil, setSelectedYil] = useState(null);

  const { currentUser } = useSelector((state) => state.auth);

  const tableRef = useRef(null);
  const excelTableRef = useRef(null);

  const yilCek = async () => {
    try {
      const response = await Servis.getYap("veriler/yilgetir");
      const yillar = response.data.map((yil) => ({
        value: yil.yil,
        label: yil.yil,
      }));
      setYillar(yillar);
      setLoading(false);
    } catch (error) {
      console.error("Yıl çekme hatası:", error);
    }
  };

  useEffect(() => {
    // kullaniciCek();
    yilCek();
  }, []);

  const veriGetir = async (selectedOption) => {
    setSelectedYil(selectedOption);

    try {
      const response = await Servis.getYap(
        "veriler/veribaslikgetir/" + currentUser.kurumid
      );
      const baslikData = response.data.map((doc) => ({
        id: doc._id,
        ...doc,
      }));

      const aylarResponse = await Servis.getYap(
        "veri/veriaylar/" + selectedOption.value
      );
      const aylarData = aylarResponse.data.map((doc) => ({
        id: doc.baslikId,
        ...doc,
      }));

      const groupedData = baslikData.map((baslik) => ({
        ...baslik,
        aylar: aylarData.filter((ay) => ay.baslikId === baslik.baslikId),
      }));

      setVeriler(groupedData);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
    //   setLoading(true);
  };

  const [showModal, setShowModal] = useState(false);
  const handleUpdate = () => {
    setShowModal(false);
    veriGetir(selectedYil);
  };

  const [selectedVeri, setSelectedVeri] = useState(null);

  const handleDuzenleClick = (veri) => {
    if (veri) {
      setSelectedVeri(veri);
      setShowModal(true);
    }
  };

  return (
    <>
      <HtmlHead title="Veri Ekle" description="" />
      <Row className="g-2 mb-5">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <h2>Kurumunuza Ait Veriler</h2>
              <hr />
              {loading ? (
                <p>Yükleniyor...</p>
              ) : (
                <>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Select
                        options={yillar}
                        value={selectedYil}
                        placeholder="Yıl seçiniz"
                        classNamePrefix="react-select"
                        onChange={veriGetir}
                      />
                    </Col>
                    <Col md="6" className="d-flex justify-content-end">
                      {selectedYil && (
                        <DownloadTableExcel
                          filename={`${selectedYil?.label || "Veri"} Verileri`}
                          sheet="Veriler"
                          currentTableRef={excelTableRef.current}
                        >
                          <Button
                            variant="outline-danger"
                            className="mb-1"
                            size="sm"
                          >
                            Excele Aktar
                          </Button>
                        </DownloadTableExcel>
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="12" className="mb-3">
                      <div style={{ display: "none" }}>
                        <Table
                          ref={excelTableRef}
                          responsive
                          className="table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Veri Başlığı</th>
                              {[
                                "Ocak",
                                "Şubat",
                                "Mart",
                                "Nisan",
                                "Mayıs",
                                "Haziran",
                                "Temmuz",
                                "Ağustos",
                                "Eylül",
                                "Ekim",
                                "Kasım",
                                "Aralık",
                              ].map((ay) => (
                                <th key={ay}>{ay}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {veriler.map((veri, index) => (
                              <tr key={veri._id}>
                                <td>{index + 1}</td>
                                <td>{veri.veriadi}</td>
                                {[
                                  "Ocak",
                                  "Şubat",
                                  "Mart",
                                  "Nisan",
                                  "Mayıs",
                                  "Haziran",
                                  "Temmuz",
                                  "Ağustos",
                                  "Eylül",
                                  "Ekim",
                                  "Kasım",
                                  "Aralık",
                                ].map((ay) => {
                                  const ayVerisi = veri.aylar.find(
                                    (a) => a.ay === ay
                                  );
                                  return (
                                    <td key={ay}>
                                      {ayVerisi ? ayVerisi.veri : "-"}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <Table
                        ref={tableRef}
                        responsive
                        className="table table-hover"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Veri Başlığı</th>
                            {[
                              "Ocak",
                              "Şubat",
                              "Mart",
                              "Nisan",
                              "Mayıs",
                              "Haziran",
                              "Temmuz",
                              "Ağustos",
                              "Eylül",
                              "Ekim",
                              "Kasım",
                              "Aralık",
                            ].map((ay) => (
                              <th key={ay}>{ay}</th>
                            ))}
                            <th className="text-center">İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {veriler.map((veri, index) => (
                            <tr key={veri._id}>
                              <td>{index + 1}</td>
                              <td>{veri.veriadi}</td>
                              {[
                                "Ocak",
                                "Şubat",
                                "Mart",
                                "Nisan",
                                "Mayıs",
                                "Haziran",
                                "Temmuz",
                                "Ağustos",
                                "Eylül",
                                "Ekim",
                                "Kasım",
                                "Aralık",
                              ].map((ay) => {
                                const ayVerisi = veri.aylar.find(
                                  (a) => a.ay === ay
                                );
                                return (
                                  <td key={ay}>
                                    {ayVerisi ? ayVerisi.veri : "-"}
                                  </td>
                                );
                              })}
                              <td className="text-center">
                                <Button
                                  variant="outline-primary"
                                  className="mb-1"
                                  size="sm"
                                  onClick={() => handleDuzenleClick(veri)}
                                >
                                  Düzenle
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <VeriDuzenleModal
                    show={showModal}
                    handleClose={() => {
                      setShowModal(false);
                      setSelectedVeri(null);
                    }}
                    veri={selectedVeri}
                    yil={selectedYil?.value}
                    baslikId={selectedVeri?.baslikId}
                    handleUpdate={handleUpdate}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KurumVerileri;
